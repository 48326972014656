import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    bariResource: {
      user_id: 48,
      bari_resource_category_id: '',
      bari_resource_sub_category_id: '',
      title: '',
      link: '',
      link_type: '',
      engagement_time: '',
      engagement_time_unit: '',
      old_preview_image: '',
      preview_image: '',
      status: 1,
    },
    bariResourceCategory: {
      name: '',
      status: 1,
    },
    modal: {
      addEditBariResourceCategoryModal: false,
      addEditBariResourceSubCategoryModal: false,
    },
    bariResourceSubCategory: {
      bari_resource_category_id: '',
      name: '',
      status: 1,
    },
  },
  getters: {
    getBariResource(state) {
      return state.bariResource
    },

    getBariResourceCategory(state) {
      return state.bariResourceCategory
    },

    getBariResourceSubCategory(state) {
      return state.bariResourceSubCategory
    }
  },
  mutations: {
    toggleAddEditBariResourceCategoryModal(state, payload) {
      state.modal.addEditBariResourceCategoryModal = payload
    },

    toggleAddEditBariResourceSubCategoryModal(state, payload) {
      state.modal.addEditBariResourceSubCategoryModal = payload
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Store Bari Resource
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeBariResource({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/store`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get requested Bari Resource
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedBariResource({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Bari Resource
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateBariResource({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/update`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get all Bari resources
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getAllBariResources({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update bari resource(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusBariResource({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Update indexes of list
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _updateSortingBariResource({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/update-indexes`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => reject(error))
      })
    },

    /**
     * Delete Bari Resource(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteBariResources({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Get all active Bari resources Categories
     * @param context - Contains the commit and state object
     * @returns {Promise<unknown>}
     * @private
     */
    _getActiveBariResourceCategories({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources/category/all-active`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get all active Bari resources sub categories by category
     * @param context - Contains the commit and state object
     * @param payload - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getBariResourceSubCategoriesByCategory({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources/sub-category/by-category`, {params: payload})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Bari Resource Categories
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getBariResourceCategories({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources/category/index?page=${params.page}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get requested Bari Resource Category
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedBariResourceCategory({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources/category/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store Bari Resource Category
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeBariResourceCategory({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/category/store`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Bari Resource Category
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateBariResourceCategory({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/category/update`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update bari resource category(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusBariResourceCategories({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/category/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Delete Bari Resource Category(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteBariResourceCategories({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/category/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Get Bari Resource Sub Categories
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getBariResourceSubCategories({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources/sub-category/index?page=${params.page}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get requested Bari Resource Sub Category
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedBariResourceSubCategory({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/bari-resources/sub-category/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store Bari Resource Sub Category
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeBariResourceSubCategory({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/sub-category/store`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Bari Resource Sub Category
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateBariResourceSubCategory({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/sub-category/update`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update bari resource sub category(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusBariResourceSubCategories({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/sub-category/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Delete Bari Resource Sub Category(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteBariResourceSubCategories({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/bari-resources/sub-category/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },
  }
};
