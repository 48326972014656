<template>
  <validation-provider :rules="rules" :name="name" v-slot="{errors, valid, invalid,validated}" ref="validation_provider">
  <div class="form-group" :class="formGroupClasses" v-if="!avatar">
    <label for="" class="form-control-label" :class="{'is_required':labelRequired}" v-if="label">{{ label }}</label> <small  v-if="labelInfo">{{ labelInfo }}</small>
  <div class="custom-file" v-if="!displayImage">
    <input type="file"
           class="custom-file-input"
           :class="{'is-invalid':invalid && validated,'is-valid': files.length > 0 && validated && valid && successMessage}"
           lang="en"
           v-bind="$attrs"
           v-on="listeners"
           ref="file_input"
    />
    <label class="custom-file-label" for="customFileLang">
      {{selectedLabel}}
    </label>
    <small v-if="info" class="font-small">{{ info }}</small>
  </div>
  <div v-else class="display-image" v-loading="!showCloseImage">
    <el-image :src="uploadedImage" lazy fit="contain" class="image" @load="imageLoaded" @error="imageError" :class="uploadedImageCss"/>
    <img src="/img/icons/buttons/remove-no-bg.svg" class="close-image" alt="cross" v-if="showCloseImage" @click="closeImage">
  </div>
  <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
        {{ errors[0] }}
  </div>
  </div>
    <div v-else>
      <div  class="file-input-avatar-wrapper" :style="{'min-height': '144px','min-width': '144px'}">
      <div class="file-input-avatar">
        <el-avatar :size="144" :src="getImageURL" @error="errorHandler">
            <img src="/img/dark_blue_man.svg" />
        </el-avatar>
        <div class="file-input-avatar-overlay" :style="{ height: '144px', width: '144px' }"></div>
        <div class="file-input-avatar-button-overlay" :style="{ height: '144px', width: '144px' }" v-if="getImageURL" >
          <img class="action mr-2" src="/img/icons/buttons/show.svg" @click="openImageModal" alt="">
          <img class="action" src="/img/icons/buttons/delete.svg" @click="clearFile(true)" alt="">
        </div>
      </div>
      <div v-if="!getImageURL">
          <base-button type="sa-red" outline size="md" @click="openFileInput">Upload Photo</base-button>
      </div>
      <input type="file"
             class="d-none"
             v-bind="$attrs"
             v-on="listeners"
             ref="file_input"
             accept="image/*"
      />
    </div>
    <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
          {{ errors[0] }}
    </div>
    </div>
    <image-dialog :visible="showImageModal" :image="getImageURL" @closeDialog="showImageModal= false"/>
</validation-provider>
</template>
<script>
import ImageDialog from '@/components/Modals/ImageDialog.vue';

  export default {
    name: 'file-input',
    inheritAttrs: false,
    components:{
      ImageDialog
    },
    props: {
      label:{
        type:String,
        default:""
      },
      labelInfo:{
        type:String,
        default:""
      },
      uploadedImage: {
        type: String,
        default: ""
      },
      uploadedImageCss: {
        type: String,
        default: ""
      },
      info:{
        type:String,
        default:""
      },
      initialLabel: {
        type: String,
        default: 'No file chosen'
      },
      name:{
        type: String,
        default: "image"
      },
      rules: {
        type: [String, Array, Object],
        description: 'Vee validate validation rules',
        default: ''
      },
      successMessage: {
        type: String,
        description: "Input success message",
        default: ''
      },
      labelRequired:{
        type: Boolean,
        default: false
      },
      avatar:{
        type: Boolean,
        default: false
      },
      formGroupClasses: {
        type: [String, Array],
        default: 'mb-4'
      }
    },
    data() {
      return {
        files: [],
        displayImage: false,
        showCloseImage: false,
        imageUrl: '',
        showImageModal: false,
        uploadedImageLocal: '',
      }
    },
    mounted(){
      if(this.uploadedImage){
        this.displayImage = true;
        this.uploadedImageLocal = this.uploadedImage;
      }
      this.$refs.validation_provider.reset();
    },
    watch:{
      uploadedImage(newVal){
        if(newVal){
          this.displayImage = true;
          this.uploadedImageLocal = newVal;
        }
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          change: this.fileChange
        }
      },
      selectedLabel() {
        let fileNames = [];
        for (let file of this.files) {
          fileNames.push(file.name)
        }
        return fileNames.length ? fileNames.join(', ') : this.initialLabel
      },
      getImageURL(){
        return this.uploadedImageLocal || this.imageUrl;
      }
    },
    methods: {
      async fileChange(evt) {
        this.files = evt.target.files;
        await this.$refs.validation_provider.validate(evt);
        if(this.files[0]){
          this.imageUrl = URL.createObjectURL(this.files[0]);
        }
        this.$emit('change', this.files);
        
      },
      clearFile(emitDelete = false){
        this.files = [];
        this.imageUrl = '';
        this.uploadedImageLocal = '';
        if(!this.$refs.file_input)return;
        this.$refs.file_input.value = null;
        // Manually trigger the change event to update validation
        const inputEvent = new Event('change');
        this.$emit('deleteImage',emitDelete);
        this.$refs.file_input.dispatchEvent(inputEvent);
      },
      closeImage(){
        this.displayImage = false;
        this.showCloseImage = false;
        this.$emit('closeImage');
      },
      imageLoaded(){
        this.showCloseImage = true;
      },
      imageError(){
        this.showCloseImage = true;
      },
      errorHandler(){
        return true;
      },
      openFileInput(){
        this.$refs.file_input.click();
      },
      openImageModal(){
        this.showImageModal = true;
      }
    }
  }
</script>
<style lang="scss">
.is_required::after{
  content: "*";
  color: red;
}
.display-image{
  min-height: 46px;
  border: 1px solid #dee2e6;
  padding: 0.50rem;
  display: flex;
  justify-content: start;
  align-items: center;
  .image{
    max-width: 80%;
    margin-right: 0.5rem;
  }
  .close-image{
    cursor: pointer;
  }
}
.file-input-avatar-wrapper{
  display: flex;
  justify-content: start;
  align-items: center;
  .file-input-avatar{
    position: relative;
    margin-right: 1.5rem;
  .file-input-avatar-overlay{
    display: none;
    position: absolute;
    top: 0;
    border-radius: 50%;
    background-color: #050505CC;
    opacity: 0.75;
    z-index: 22;
  }
  .file-input-avatar-button-overlay{
    display: none;
    position: absolute;
    top: 0;
    border-radius: 50%;
    z-index: 23;
    .action{
      border-radius: 50%;
      cursor: pointer;
    }
  }
  &:hover{
    .file-input-avatar-overlay{
      display: block;
    }
    .file-input-avatar-button-overlay{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
}
</style>
